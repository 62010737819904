<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Hak Akses Jabatan</b> yang ada di Klinik IONA
      </div>
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-6">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-start mt-3">
              <div class="col-md-6">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Berdasar Nama"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- Tabel Position (Jabatan) di sebelah kiri -->
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }"
                />
              </template>
              <template #cell(actions)="data">
                <!-- <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover.right
                  :title="data.item.id"
                  @click="getAccessByPosition(data.item.id)"
                >
                  <i class="fas fa-eye px-0"></i>
                </b-button> -->
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  @click="getAccess(data.item.id)"
                >
                  Pilih
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>

      <!-- Tabel Hak Akses di sebelah kanan-->
      <div class="col-md-6">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-start mt-3">
              <div class="col-md-6">
                <h5>Hak Akses</h5>
                <h6>{{ username }}</h6>
              </div>
            </div>

            <div class="row justify-content-start mt-3">
              <div class="col-md-6">
                <b-form-input
                  id="filter-input"
                  v-model="filterAccess"
                  type="search"
                  placeholder="Cari Hak Akses"
                ></b-form-input>
              </div>
            </div>

            <!-- Table -->
            <b-table
              hover
              class="mt-3"
              :items="access_lists_display"
              :fields="fieldAccess"
              id="my-table"
              :filter="filterAccess"
              @filtered="onFiltered"
              responsive
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }"
                />
              </template>
              <template #cell(code)="data">
                <span v-if="data.item.parent_id == 0">
                  <b>{{ data.item.name }}</b>
                </span>
                <span v-if="data.item.parent_id !== 0">
                  {{ data.item.code }}
                </span>
              </template>
              <template #cell(name)="data">
                <span v-if="data.item.parent_id == 0"> </span>
                <span v-if="data.item.parent_id !== 0">
                  {{ data.item.name }}
                </span>
              </template>
              <template #cell(actions)="data">
                <span v-if="data.item.parent_id == 0"> </span>
                <span v-if="data.item.parent_id !== 0">
                  <b-button
                    size="sm"
                    v-if="data.item.access_status == true"
                    @click="detachPosition(data.item.id)"
                    class="mr-1 btn-success"
                  ><i class="fas fa-check px-0"></i></b-button>
                  <b-button
                    size="sm"
                    v-if="!data.item.access_status"
                    @click="attachPosition(data.item.id)"
                    class="mr-1 btn-danger"
                  ><i class="fas fa-times px-0"></i></b-button>
                </span>
              </template>
            </b-table>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import api from "@/core/services/api.service.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getUser, setUser } from '@/core/services/jwt.service.js'

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      filterAccess: "",
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      perPageAccess: 10,
      currentPageAccess: 1,
      totalRowsAccess: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      fieldAccess: [
        {
          key: "code",
          label: "Kode",
          sortable: true,
        },
        {
          key: "name",
          label: "Fitur / Modul Sistem",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items Doctor
      items: [],
      // Table Items Employee
      itemsEmployee: [],
      // Table Items Access List
      access_lists: [],
      access_lists_children: [],
      access_lists_display: [],
      //
      access_by_user: [],
      // user name
      username: "",
      position_id: "",
    };
  },

  methods: {
    resetArrayOnNew() {
      this.access_lists = [];
      this.access_lists_children = [];
      this.access_lists_display = [];
      this.access_by_user = [];
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsAccess = filteredItems.length;
      this.currentPage = 1;
    },

    // function tabel dokter di sebelah kiri
    async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "positions",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.items = response.data;
      this.totalRows = pagination.total;
    },

    filterByName() {
      this.pagination();
    },

    // function tabel hak akses sebelah kanan
    async getAccessList() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "access-lists",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      this.access_lists = response.data;
      console.log("default", this.access_lists);

      this.access_lists_display = [];
      let a, b, c;
      for (a = 0; a < this.access_lists.length; a++) {
        this.access_lists_children = response.data[a].children;
        this.access_lists_display.push(this.access_lists[a]);
        for (b = 0; b < this.access_lists_children.length; b++) {
          this.access_lists_display.push(this.access_lists_children[b]);
          for (c = 0; c < this.access_lists_display.length; c++) {
            if (this.access_lists_display[c].parent_id !== 0) {
              this.access_lists_display[c].number = "-";
            }
          }
        }
      }
      console.log("hasil", this.access_lists_display);
      this.totalRowsAccess = this.access_lists_display.length;
    },

    getAccess(id){
      this.resetArrayOnNew()
      this.getAccessByPosition(id)
    },

    async getAccessByPosition(id) {
      // this.resetArrayOnNew();

      let access_by_user = await module.getAccessByUser("positions/" + id);
      this.username = access_by_user.data.name;
      this.position_id = access_by_user.data.id;
      console.log("username", this.username)
      console.log("position_id", this.position_id)
      let access_right = access_by_user.meta.access_right.split(",");
      // If Data Not Found
      console.log("acc", access_by_user);
      console.log("accr", access_right);

      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "access-lists",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      this.access_lists = response.data;
      console.log("default", this.access_lists);

      this.access_lists_display = [];
      let a, b, c, d;
      for (a = 0; a < this.access_lists.length; a++) {
        this.access_lists_children = response.data[a].children;
        this.access_lists_display.push(this.access_lists[a]);
        for (b = 0; b < this.access_lists_children.length; b++) {
          this.access_lists_display.push(this.access_lists_children[b]);
          for (c = 0; c < this.access_lists_display.length; c++) {
            if (this.access_lists_display[c].parent_id !== 0) {
              this.access_lists_display[c].number = "-";
            }
            for (d = 0; d < access_right.length; d++) {
              if (this.access_lists_display[c].code == access_right[d]) {
                this.access_lists_display[c].access_status = true;
              }
            }
          }
        }
        console.log("hasil akhir", this.access_lists_display);
      }
    },

    async attachPosition(id) {
      let targetUser = this.user_id
      let done = await api
        .post("access-lists/" + id + "/" + this.position_id + "/attach/position")
        .then((response) => {
          console.log("attach position", response);
          this.getAccessByPosition(this.position_id);
          if (targetUser == getUser().user_id) {
            localStorage.setItem('access_right', response.data.access_right)
            localStorage.setItem('access_right_display', JSON.stringify(response.data.access_right_display))
            return true
          }
        });
      if (done) {
        this.$root.$emit('setMenuActive');
        this.$root.$emit('UpdateSideMenu');
      }
    },

    async detachPosition(id) {
      let targetUser = this.user_id
      console.log("detach");
      let done = await api
        .delete("access-lists/" + id + "/" + this.position_id + "/detach/position")
        .then((response) => {
          console.log("detach position", response);
          this.getAccessByPosition(this.position_id);
          if (targetUser == getUser().user_id) {
            localStorage.setItem('access_right', response.data.access_right)
            localStorage.setItem('access_right_display', JSON.stringify(response.data.access_right_display))
            return true
          }
        });
      if (done) {
        this.$root.$emit('setMenuActive');
        this.$root.$emit('UpdateSideMenu');
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("action-types/" + id);
      // If Deleted
      if (result) {
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Hak Akses Jabatan" }]);
    // Get Data
    // this.getAccessList();
    this.pagination();
  },
};
</script>
